import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module'
import {FormsModule} from '@angular/forms';
import {SharedModule} from './Shared/shared.module'
import { MeetingService } from './Services/meeting.service';
import { RouterModule } from '@angular/router';
@NgModule({
declarations: [
  AppComponent,

],
imports: [
  BrowserModule,
  HttpClientModule,
  BrowserAnimationsModule,
  FormsModule,
  MaterialModule,
  SharedModule,
  RouterModule.forRoot([])
],
providers: [MeetingService],
bootstrap: [AppComponent],
entryComponents:[]
})
export class AppModule { }
