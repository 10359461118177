import { Injectable } from '@angular/core';
import { ModalService } from '../Shared/Services/modal.service';
import { Meeting } from '../Models/meeting.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { timeout, map, retry, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  apiBaseUrl: string = '';

  constructor(private modal: ModalService, private httpClient: HttpClient) {
    this.apiBaseUrl = 'https://websdk--signature.herokuapp.com';
  }

  saveMeeting(dataModel: Meeting): Observable<any> {
    let url: string = '';
    let method: string = '';
    if (dataModel._id) {
      url = '/meeting/edit';
      method = 'put';
    } else {
      url = '/meeting/post';
      method = 'post';
    }
    return this.httpClient
      .request(method, this.apiBaseUrl + url, {
        body: dataModel,
      })
      .pipe(
        timeout(60000),
        map((res: any) => {
          if (!res) return;
          console.log(res);
          return res.data;
        }),
        retry(1),
        catchError((err) =>
          this.modal.openValidationDialog('warning', 'Error', err.error.error)
        )
      );
  }

  deleteMeeting(meetingID): Observable<any> {
    return this.httpClient
      .delete(this.apiBaseUrl + '/meeting/delete/' + meetingID)
      .pipe(
        timeout(60000),
        map((res: any) => {
          if (!res || res.data) return;
          return res;
        }),
        retry(),
        catchError((err) =>
          this.modal.openValidationDialog('warning', 'Error', err.error.error)
        )
      );
  }

  getMeetings(): Observable<any> {
    return this.httpClient.get(this.apiBaseUrl + '/meeting/get').pipe(
      timeout(60000),
      map((res: any) => {
        console.log(res);
        if (!res || !res.data) return;

        console.log(res.data);

        return res.data;
      }),
      retry(0),
      catchError((err) =>
        this.modal.openValidationDialog('warning', 'Error', err.error.error)
      )
    );
  }

  checkUserInfo(email, password, roomID): Observable<any> {
    let apiBaseUrl = 'https://me-events.me/api/check_subscriber';
    return this.httpClient
      .post(apiBaseUrl, { email: email, password: password, roomID: roomID })
      .pipe(
        timeout(60000),
        map((res: any) => {
          console.log(res);
          if (!res) return;

          console.log(res.data);

          return res;
        }),
        retry(0),
        catchError((err) =>
          this.modal.openValidationDialog('warning', 'Error', err.error.error)
        )
      );
  }

  checkAccessEvent(email, roomID): Observable<any> {
    let apiBaseUrl = 'https://me-events.me/api/access_event';
    return this.httpClient
      .post(apiBaseUrl, { email: email, roomID: roomID })
      .pipe(
        timeout(60000),
        map((res: any) => {
          console.log(res);
          if (!res) return;

          console.log(res.data);

          return res;
        }),
        retry(0),
        catchError((err) =>
          this.modal.openValidationDialog('warning', 'Error', err.error.error)
        )
      );
  }

  getMeetingPassword(meetingNumber): Observable<any> {
    let apiBaseUrl = `https://me-events.me/api/RoomInfo?room_id=${meetingNumber}`
    return this.httpClient.get(apiBaseUrl).pipe(
      timeout(60000),
      map((res: any) => {
        console.log(res);
        if (!res || !res.data) return;

        console.log(res.data);

        return res;
      }),
      retry(0),
      catchError((err) =>
        this.modal.openValidationDialog('warning', 'Error', err.error.error)
      )
    );
  
  }

}
