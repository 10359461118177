



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../material.module'
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms'

import {ModalService,ValidationMessageDialogComponent} from './index'

@NgModule({
  declarations: [
    ValidationMessageDialogComponent


  ],
  imports: [

    HttpClientModule,
    FormsModule,
    CommonModule,
    MaterialModule,





  ],
  providers: [ModalService],
  entryComponents: [ValidationMessageDialogComponent]
})
export class SharedModule { }
