import { Injectable } from "@angular/core";
import { ValidationMessageDialogComponent } from "../Components/validation-message-dialog/validation-message-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(public dialog: MatDialog, private snackBar: MatSnackBar) {}

  openValidationDialog(
    icon: String,
    title: String,
    message: String,
    width?: string,
    cancelButton?: boolean
  ): Observable<any> {
    let defaultWidth: string = "500px";

    const dialogRef = this.dialog.open(ValidationMessageDialogComponent, {
      width: width ? width : defaultWidth,
      data: {
        icon: icon,
        title: title,
        message: message,
        cancelButton: cancelButton ? true : false,
      },
    });
    return dialogRef.afterClosed();
  }

  showSnackBar(message) {
    this.snackBar.open(message, "OK", { duration: 2000 });
  }

}

class FileUpload {
  file: any = undefined;
  fileName: string = "";
  fileUrl: string = "";
}
