









<div class="container" style="margin:auto">
  <br />

  <div class="list-container">
    <div style="flex: 20;"></div>

  


   <div class="itemCard" >
    <mat-card  style=" margin: auto; border-radius: 5px; ">
      <form class="form-inline" name="loginForm" #form="ngForm">
        <div style="width: 100%; text-align: center;">
          <h3 style="color: black; font-size: 19px; font-weight: 500;">Please Fill All The Following Fields</h3>
        </div>

        <div class="form-group" style="flex-basis: 100%;">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              #ngEmail="ngModel"
              placeholder="Ex:email@yahoo.com"
              type="email"
              required
              [(ngModel)]="userEmail"
              name="txtUserEmail"
            />
            <mat-hint *ngIf="ngEmail.touched && !ngEmail.valid"
              >Please fill this field</mat-hint
            >
          </mat-form-field>
        </div>

        <div class="form-group" style="flex-basis: 100%; margin-top: -10px;">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              #ngUserName="ngModel"
              placeholder="User Name"
              required
              [(ngModel)]="userName"
              name="txtUserName"
            />
            <mat-hint *ngIf="ngUserName.touched && !ngUserName.valid"
              >Please fill this field</mat-hint
            >
          </mat-form-field>
        </div>

        <!-- <div class="form-group" style="flex-basis: 100%;margin-top: -10px;">
          <mat-form-field appearance="outline">
            <mat-label>Meeting ID</mat-label>
            <input
              matInput
              #ngMeetingNumber="ngModel"
              placeholder="Meeting #"
              required
              type="number"
              [(ngModel)]="meetingNumber"
              name="txtMeetingNumber"
            />
            <mat-hint *ngIf="ngMeetingNumber.touched && !ngMeetingNumber.valid"
              >Please fill this field</mat-hint
            >
          </mat-form-field>
        </div> -->

        <!-- <div class="form-group" style="flex-basis: 100%;margin-top: -10px;">
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              matInput
              #ngPassword="ngModel"
              placeholder="Password"
              required
              type="password"
              [(ngModel)]="passWord"
              name="txtPassword"
            />
            <mat-hint *ngIf="ngPassword.touched && !ngPassword.valid"
              >Please fill this field</mat-hint
            >
          </mat-form-field>
        </div> -->

        <div class="form-group" style="flex-basis: 100%;margin-top: 0px;">
          <button
            style="width: 100%;height: 50px; font-size: 18px; background-color: #0000FF; color: #fff !important;border-radius: 30px;"
            mat-raised-button
            (click)="checkUserInfo()"
            [disabled]="form.invalid || isBusy"
          >
            Go to lecture
          </button>


      

          <mat-progress-bar *ngIf="isBusy"
            color="warn"
            mode="indeterminate"
          >
          </mat-progress-bar>
        </div>

        <!-- <div class="form-group" style="flex-basis: 100%;margin-top: 5px;">
          <button
            style="width: 100%;height: 50px; font-size: 18px; background-color: olivedrab; color: #fff !important"
            mat-raised-button
            (click)="returnBack()"
          >
            Close
          </button>

          


        
        </div> -->
      </form>
    </mat-card>
   </div>


    <div style="flex: 20;"></div>

  </div>
  <br />
</div>



<!-- <button  (click)="closeWin()">Close With</button> -->
