import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Injector, VERSION } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { ZoomMtg } from '@zoom/meetingsdk';
import { Meeting } from './Models/meeting.model';
import { MeetingService } from './Services/meeting.service';
import { ModalService } from './Shared/Services/modal.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  name = 'Angular ' + VERSION.major;

  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  // signatureEndpoint = 'https://websdk--signature.herokuapp.com';
  signatureEndpoint =
    'https://us-central1-events-app-e80f6.cloudfunctions.net/getSignature';
  apiKey = 'hcuyTh1oS9artYs7iEFuow';
  sdkKey = 'Mb79oBB1BdQ7uX6OAsWcFBaxCGeKHZoPOZc7';
  meetingNumber: number;
  role = 0;
  leaveUrl = 'https://viexpos.com/';
  userName = '';
  userEmail = '';
  passWord: string;
  isBusy: boolean = false;
  customWindow;

  myWindow;

  firstVisit: boolean;

  destroySession: boolean = false;

  adminID: number = 7777888810;
  adminPassword: string = '456123';

  meetingStarted: boolean = false;


  previousUrl: string;

  meetings: Meeting[] = [];

  constructor(
    injector: Injector,
    private router: Router,
    public httpClient: HttpClient,
    @Inject(DOCUMENT) document,
    private location: Location,
    private meetingService: MeetingService,
    private modal: ModalService,
    private route: ActivatedRoute
    
  ) {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    
  }

  ngOnInit() {
    this.route.queryParams.subscribe((paramMap) => {
      let onLeave = paramMap.onLeave;
      if (onLeave) {
        console.log(onLeave);
        this.checkAccessEvent();
      }
    });

    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      if (params.id) {
        this.getPassword(params.id);
      }
    });
  }

  getPassword(meetingNumber) {
    this.meetingService.getMeetingPassword(meetingNumber).subscribe((res) => {
      if (res && res.status == false) {
        this.modal
          .openValidationDialog(
            'warning',
            'Error',
            'Sorry this is an invalid link so contact your system administrator'
          )
          .subscribe((res) => {
            return;
          });
      } else {
        this.passWord = res?.data?.password;
        this.meetingNumber = res?.data?.room_id;
      }
    });
  }

  async getSignature() {
    console.log(this.meetingNumber);

    this.isBusy = true;
    // try {
    // const data: SignatureResponse = await this.httpClient.post<SignatureResponse>(this.signatureEndpoint, {
    //     meetingNumber: this.meetingNumber,
    //     role: this.role,
    //   }).toPromise();
    //   if (data?.signature) {
    //     console.log("data.signature",data.signature)
    //     this.startMeeting(data?.signature);
    //   }
    // } catch (error) {
    //   console.error(error);

    // }
    // this.isBusy = false;

    this.httpClient
      .post(this.signatureEndpoint, {
        meetingNumber: this.meetingNumber,
        role: this.role,
      })
      .toPromise()
      .then((data: any) => {
        if (data.signature) {
          console.log(data.signature);
          this.startMeeting(data.signature);
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log(error);
        this.isBusy = false;
      });
  }

  startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block';
    // leaveUrl: this.leaveUrl,

    console.log(ZoomMtg);
    ZoomMtg.init({
      leaveUrl: 'https://speakers.me-events.me/?onLeave=true',

      isSupportAV: true,
      disableJoinAudio: false,
      disablePreview: true,
      videoHeader: true,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: signature,
          meetingNumber: Number(this.meetingNumber),
          userName: this.userName,
          sdkKey: this.sdkKey,
          userEmail: this.userEmail,
          passWord: this.passWord,
          tk: '',
          success: (success) => {
            console.log(success);
            this.checkAccessEvent();
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngOnDestroy() {
    // alert("indezzzzzzzzzzzzzzzzzzzzzz");
    // window.open("../../../destory.html", "_self");
    // localStorage.setItem('onUserLeave', 'onUserLeave');
    //localStorage.clear();
  }

  close() {
    // history.go(-1);
    // return true;

    //alert("indezzzzzzzzzzzzzzzzzzzzzz");
    // window.open('../../../destory.html', '_self').close()
    // window.location.href = '../../../destory.html'
    //  let test =  window.open(
    //     "https://stackoverflow.com/questions/19761241/window-close-and-self-close-do-not-close-the-window-in-chrome",
    //     "_self",
    //     "width=786, height=786"
    //   ).close();

    window.top.close();
  }

  openWin() {
    this.myWindow = window.open(
      '../../../destory.html',
      'myWindow',
      'width=10, height=10'
    ); // Opens a new window
  }

  closeWin() {
    // this.myWindow.close();   // Closes the new window

    // this.location.back()
    window.close();
  }

  fillMeetings() {
    this.meetingService.getMeetings().subscribe(
      (res) => {
        this.meetings = res;
      },
      (error) => {}
    );
  }

  returnBack() {
    // localStorage.clear();
    window.close();
  }

  checkUserInfo() {
    localStorage.setItem('email', this.userEmail);
    localStorage.setItem('password', this.passWord);
    localStorage.setItem('meetingID', this.meetingNumber.toString());
    var name = this.userEmail.substring(0, this.userEmail.lastIndexOf('@'));
    var domain = this.userEmail.substring(this.userEmail.lastIndexOf('@') + 1);
    var emailnew =
      name + '_' + Math.floor(Math.random() * 1000 + 1) + '@' + domain;
    var emailold = this.userEmail;
    this.userEmail = emailnew;
    //localStorage.setItem('email', this.userEmail);
    localStorage.setItem('email', emailnew);

    this.isBusy = true;
    this.meetingService
      .checkUserInfo(emailold, this.passWord, String(this.meetingNumber))
      .subscribe(
        (res) => {
          this.isBusy = false;
          if (res.status == true) {
            this.getSignature();
          } else {
            //window.location.href = "https://me-events.me/ar/subscribe_event/4";
            window.location.href = 'https://me-events.me/public/ar/register';

            this.modal
              .openValidationDialog(
                'warning',
                'Error',
                'Sorry you have no permission to join this meeting '
              )
              .subscribe((res) => {
                // this.location.back();
                // window.close();
                return;
              });
          }
        },
        (error) => {
          this.isBusy = false;
        }
      );
  }

  checkAccessEvent() {
    this.meetingService
      .checkAccessEvent(
        localStorage.getItem('email'),
        String(localStorage.getItem('meetingID'))
      )
      .subscribe(
        (res) => {
          this.isBusy = false;
        },
        (error) => {}
      );
  }
}
